<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          v-model="listQuery.operationId"
          placeholder="请选择代理商类型"
          :maxLength="50"
          clearable
        >
          <el-option
            v-for="(item, i) in agentList"
            :value="item.id"
            :label="item.operationName"
            :key="i"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        label="代理商名称"
        prop="operationName"
      ></el-table-column>

      <el-table-column
        label="充值天数"
        prop="days"
        align="right"
      >
      <template #default="scope" >
          <span></span>
          <span v-if="scope.row.chargeType == 1">{{ scope.row.days }}</span>
          <span v-else> -- </span>
        </template>
      </el-table-column>

      <el-table-column label="充值方式" prop="chargeWay">
        <template #default="scope">
          <eb-badge
            :list="this.const.AGENT_CHARGE_WAY"
            :target="scope.row.chargeWay"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="充值比例" prop="chargeScale">
        <template #default="scope" >
          <span></span>
          <span v-if="scope.row.chargeType == 1">{{ (scope.row.chargeScale) }}</span>
          <span v-else>{{ 1 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="充值类型" prop="chargeType">
        <template #default="scope">
          <span v-if="scope.row.chargeType == 1">天数充值</span>
          <span v-else>缴纳押金</span>
        </template>
      </el-table-column>

      <el-table-column label="预计到账金额" prop="chargeScale">
        <template #default="scope" >
          <span></span>
          <span v-if="scope.row.chargeType == 1">{{ (scope.row.chargeScale) * scope.row.days }}</span>
          <span v-else>{{ scope.row.days / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.AGENT_CHARGE_APPLY"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" prop="createAt"></el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            v-if="scope.row.status == this.const.AGENT_CHARGE_APPLY_CODE.WAIT"
            class="option option-primary"
            @click="doAudit(scope.row, this.const.AGENT_CHARGE_APPLY_CODE.PASS)"
            >通过审核</span
          >

          <span
            v-if="scope.row.status == this.const.AGENT_CHARGE_APPLY_CODE.WAIT"
            class="option option-danger"
            @click="
              doAudit(scope.row, this.const.AGENT_CHARGE_APPLY_CODE.REJECT)
            "
            >驳回审核</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { agentChargeRecordPage, addAgentChargeApply } from "@/api/agent";

export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList;
    },
  }),
  data() {
    return {
      
      dataSource: [],
      listQuery: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    //提现申请审核
    doAudit(data, type) {
      let pass = <span class="text-primary">通过</span>;
      let reject = <span class="text-danger">驳回</span>;
      let s = (
        <div>
          是否要{" "}
          {type === this.const.AGENT_CHARGE_APPLY_CODE.PASS ? pass : reject}{" "}
          提现审核?
        </div>
      );
      this.$confirm(s, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        addAgentChargeApply({
          status: type,
          id: data.id,
        }).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getList();
        });
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      agentChargeRecordPage({ ...this.listQuery, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch("fetchAgentListAll").then((res) => {
      this.getList();
    });
  },
};
</script>
